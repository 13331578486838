import styled from "styled-components"

const LyricsSection = styled.div``

const LyricsSectionSongList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
`

const LyricsSectionSong = styled.div`
  text-transform: uppercase;
  display: grid;
  grid-template-columns: ${({ theme }) => `${theme.space[6]} 1fr`};
  grid-template-rows: auto;
  grid-template-areas: "header header";
  border-bottom: 2px solid ${({ theme }) => theme.color.darkBrown};
  padding-bottom: ${({ theme }) => theme.space[2]};

  h3 {
    font-family: "ApfelGrotezk-Regular", "Helvetica", Arial, sans-serif;
    width: 100%;
    grid-area: header;
  }

  h3,
  p {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  @media ${({ theme }) => theme.device.mobileL} {
    grid-template-columns: ${({ theme }) => `${theme.space[7]} 1fr`};
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: ${({ theme }) =>
      `1fr ${theme.space[8]} ${theme.space[9]}`};
    grid-template-areas: "header";
    align-items: center;

    h3,
    p {
      font-size: ${({ theme }) => theme.fontSize[6]};
    }
  }
`

export default {
  LyricsSection,
  LyricsSectionSong,
  LyricsSectionSongList,
}
