import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Styles from "./Styles"
import Section from "../../common/Section"
import ButtonLink from "../../common/ButtonLink"
import Link from "../../common/Link"

const LyricsSection = ({ id, heading, number }) => {
  return (
    <StaticQuery
      query={graphql`
        query songsQuery {
          allPrismicSong {
            edges {
              node {
                data {
                  name {
                    text
                  }
                  year {
                    text
                  }
                }
                uid
                id
              }
            }
          }
        }
      `}
      render={(data) => {
        const songs = data.allPrismicSong.edges
        console.log(songs)
        return (
          <Styles.LyricsSection>
            <Section number={number} heading={heading} id={id}>
              <Styles.LyricsSectionSongList>
                {songs.map(({ node }) => (
                  <Styles.LyricsSectionSong>
                    <h3>
                      <Link to={`/songs/${node.uid}`}>
                        {node.data.name.text}
                      </Link>
                    </h3>
                    <p>{node.data.year.text}</p>
                    <p>
                      <Link to={`/songs/${node.uid}`}>Read &rarr;</Link>
                    </p>
                  </Styles.LyricsSectionSong>
                ))}
              </Styles.LyricsSectionSongList>
            </Section>
          </Styles.LyricsSection>
        )
      }}
    />
  )
}

export default LyricsSection
