import * as React from "react"
import Layout from "../components/layout/Layout"
import HomeHeader from "../components/content/HomeHeader"

import MusicSection from "../components/content/MusicSection"
import VideoSection from "../components/content/VideoSection"
import AboutSection from "../components/content/AboutSection"
import LyricsSection from "../components/content/LyricsSection"
import ConnectSection from "../components/content/ConnectSection"
import ShowsSection from "../components/content/ShowsSection"

import { Styles as SectionStyles } from "../components/common/Section"

const sections = [
  {
    id: "connect",
    heading: "Connect",
    component: ConnectSection,
  },
  {
    id: "music",
    heading: "Music (In Stereo)",
    component: MusicSection,
  },
  {
    id: "video",
    heading: "Video",
    component: VideoSection,
  },
  {
    id: "about",
    heading: "About",
    component: AboutSection,
  },
  {
    id: "shows",
    heading: "Shows",
    component: ShowsSection,
  },
  {
    id: "lyrics",
    heading: "Lyrics",
    component: LyricsSection,
  },
  {
    id: "connect",
    heading: "Connect",
    component: ConnectSection,
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <HomeHeader />
      <SectionStyles.SectionColumn>
        {sections.map(({ id, heading, component }, index) =>
          React.createElement(component, {
            key: id,
            id,
            heading,
            number: index + 1,
          })
        )}
      </SectionStyles.SectionColumn>
    </Layout>
  )
}

export default IndexPage
